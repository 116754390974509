<template>
  <h5>Login</h5>
  <div class="field grid">
    <label for="email" class="col-12 mb-2 md:col-2 md:mb-0">Email</label>
    <div class="col-12 md:col-10">
      <InputText id="email" v-model="form.email" type="text" />
    </div>
  </div>
  <div class="field grid">
    <label for="password" class="col-12 mb-2 md:col-2 md:mb-0">Password</label>
    <div class="col-12 md:col-10">
      <InputText id="password" v-model="form.password" type="password" />
    </div>
  </div>

  <Button @click="validateForm"> Login </Button>
  <label>{{ form.errorMsg }}</label>
</template>

//
<script>
import auth from "../service/auth";

export default {
  data() {
    return {
      showValidateMsg: false,

      form: {
        email: null,
        password: null,
        errorMsg: null,
      },
    };
  },
  created() {
    if (this.$store.getters.isUser) {
      this.$router.push("/");
    }
  },
  methods: {
    async login() {
      const result = await auth.login({
        identifier: this.form.email,
        password: this.form.password,
      });
      //console.log(result);
      const { userId, statusCode, error, message } = result;
      console.log(result);
      if (userId) {
        this.$router.push("/");
      } else if (statusCode && error) {
        //console.log(statusCode, error, message);
        if (Array.isArray(message) && message.length > 0 && Array.isArray(message[0].messages)) {
          message[0].messages.forEach((item) => {
            console.log(item.message);
            this.form.errorMsg = item.message;
          });
        }
      }
    },
    validateForm() {
      var email = true;
      var password = true;
      if (this.form.email == "" || this.form.email == null) {
        email = false;
        this.showValidateMsg = true;
      }
      if (this.form.password == "" || this.form.password == null) {
        password = false;
        this.showValidateMsg = true;
      }
      if (email == true && password == true) {
        this.showValidateMsg = false;
        this.login();
      }
    },
  },
};
</script>

//
<style scoped lang="scss"></style>
