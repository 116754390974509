import kycApi from "../kyc-api";
import store from "../store";

const service = {
  /**
   * Login
   * @param {Object} requestBody
   * @param {string} [requestBody.identifier]
   * @param {string} [requestBody.password]
   * @returns {Object<User>}
   */
  login: async (requestBody) => {
    try {
      return await kycApi
        .post("/auth/local", requestBody)
        .then((data) => {
          console.log(data);
          const { user, jwt } = data;
          if (user) {
            return store.dispatch("login", { user: user, jwt }).then(() => {
              return { userId: user.id };
            });
          }
        })
        .catch((error) => {
          return error;
        });
    } catch (err) {
      return err;
    }
  },
};

export default service;
