import axios from "axios";
import store from "./store";

const kycApi = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

kycApi.CancelToken = axios.CancelToken;
kycApi.isCancel = axios.isCancel;

/*
 * The interceptor here ensures that we check for the token in local storage every time an ajax request is made
 */
kycApi.interceptors.request.use(
  async (config) => {
    config.headers["Content-Type"] = "application/json";
    let jwt = store.getters.jwt;
    if (null != jwt) {
      config.headers["Authorization"] = `Bearer ${jwt}`;
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);
kycApi.interceptors.response.use(
  (res) => res.data,
  (err) => {
    if (!err.response) {
      return Promise.reject({
        error: "No Response Error",
        message: err.message,
      });
    } else if (err.response.status === 403 && err.response.config && !err.response.config.__isRetryRequest) {
      return Promise.reject(err.response.data);
    } else if (err.response.status === 400 || err.response.status === 401) {
      if (err.response.data && err.response.data.statusCode && err.response.data.message) {
        return Promise.reject(err.response.data);
      }
    } else if (err.response && err.response.data) {
      return Promise.reject(err.response.data);
    }
    return Promise.reject(err);
  }
);

export default kycApi;
